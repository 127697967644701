import React from 'react'
import "./ProductSilder.css"
import { NavLink } from 'react-router-dom'

const ProductSilder = () => {
    return (
        <>

            <section>
                <div className="container-fluid">
                    <div className="container-lg mt-5 pt-5">
                        <div className="row g-4 pb-5">
                            <div className="col-xl-3 col-lg-3">
                                <div className=''>
                                    <img className='w-100'src={require("../Img/MainProduct.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-xl-9 col-lg-9">
                                <div className="testimonials mt-lg-4">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <h2 className='ms-lg-5 laila-semibold'>
                                                Hot Deals
                                            </h2>
                                            <div className="carousel-item active">
                                                <div className="single-item">
                                                    <div className="row g-4 d-flex justify-content-center" >
                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-1.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-3.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="carousel-item">
                                                <div className="single-item">
                                                    <div className="row g-4 d-flex " >
                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-4.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-5.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                            <div className='ProductBox'>
                                                                <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                                    <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                                                                </div>
                                                                <div className='mt-2 text-center'>
                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                        <h2 className='ProductText' >
                                                                            Meatballs
                                                                        </h2>
                                                                        <h6 >
                                                                            $ 20.00
                                                                        </h6>

                                                                        <div>
                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                            </ul>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                            <span className='LeftArrow'>
                                                <i className="fa-solid fa-arrow-left"></i>
                                            </span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                            <span className='RightArrow'>
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSilder