import React from 'react'
import "./ThirdSilder.css"
import { NavLink } from 'react-router-dom'

const ThirdSilder = () => {
    return (
        <>

            <section>
                <div className="container-fluid">
                    <div className="container-lg mt-5 pt-5">
                        <div className='TopCategories d-flex justify-content-between align-items-center'>
                            <div className='text-start'>
                                <h2>
                                    Weekly Hot
                                </h2>
                                <h6>
                                    Products
                                </h6>
                            </div>

                            <div className='ViewAll'>
                                <button>
                                    View All
                                </button>
                            </div>
                        </div>
                        <div className="row p-2 g-4 pb-5">
                            <div className="testimonials mt-lg-4">
                                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row row-cols-xl-5 row-cols-lg-5  g-4 d-flex justify-content-center" >
                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct3.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct4.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct5.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row row-cols-xl-5 row-cols-lg-5 g-4 d-flex " >
                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct3.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct4.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=" ">
                                                    <div className='ProductBox '>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/WeecklyHotProduct5.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className=' mt-2 text-center'>
                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                <h2 className='ProductText' >
                                                                    Meatballs
                                                                </h2>
                                                                <h6 >
                                                                    $ 20.00
                                                                </h6>

                                                                <div>
                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="carousel-item">
                                            <div className="row g-4 d-flex " >
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider4.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Wraps & Rolls
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider5.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Strawberries (250g)
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Spinach
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Kale
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default ThirdSilder