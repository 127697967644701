import React, { useState } from 'react'
import "./TernaryOperator.css"
import { NavLink } from 'react-router-dom'

const TernaryOperator = () => {

    const [display, setDisplay] = useState("ALL");

    const [btn1, setBtn1] = useState("newStyle");

    const [btn2, setBtn2] = useState("btnStyle");

    const [btn3, setBtn3] = useState("btn3Style");

    const [btn4, setBtn4] = useState("btn4Style");


    const changleDisplay = () => {
        setDisplay("ALL")
        setBtn1("newStyle")
        setBtn2("btnStyle")
    }

    const changleDisplay1 = () => {
        setDisplay("OFFICE")
        setBtn1("btnStyle")
        setBtn2("newStyle")
    }

    const changleDisplay3 = () => {
        setDisplay("CLUB")
        setBtn3("btn3Style")
        setBtn4("btn4Style")
    }

    const changleDisplay4 = () => {
        setDisplay("RESIDENTIAL")
        setBtn3("btn4Style")
        setBtn4("btn3Style")
    }



    return (
        <>

            <section>
                <div className="container-fluid" >
                    <div className="p-lg-5 lato-black">
                        <div className=" pt-5 ">
                            <div className='RecentProjectMainContent'>
                                <h2>
                                    Feature Products
                                </h2>
                                <h6>
                                    Latest Arrivals
                                </h6>
                            </div>
                            <div className="row p-lg-4 d-flex justify-content-center align-items-center ">
                                <div className="row g-2 pb-lg-2 d-flex justify-content-center text-center align-items-center text-white "  >
                                    <div className="col-lg-1 col-md-2 col-sm-2 col-3 text-center lato-black"  >
                                        <img className='mb-2' style={{ height: "40px", width: "40px" }} src={require("../Img/salad.png")} alt='works-at-iclodsoft' />
                                        <button className={btn1} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay}>SHOW ALL</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3 text-center lato-black"  >
                                        <img className='mb-2' style={{ height: "40px", width: "40px" }} src={require("../Img/salad.png")} alt='works-at-iclodsoft' />
                                        <button className={btn3} style={{ fontWeight: "600", fontSize: "13px", letterSpacing: "2px" }} onClick={changleDisplay3}>MEAT</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3 text-center lato-black"  >
                                        <img className='mb-2' style={{ height: "40px", width: "40px" }} src={require("../Img/salad.png")} alt='works-at-iclodsoft' />
                                        <button className={btn2} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay1}>SEASON</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3 text-center  lato-black"  >
                                        <img className='mb-2' style={{ height: "40px", width: "40px" }} src={require("../Img/salad.png")} alt='works-at-iclodsoft' />
                                        <button className={btn4} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay4}>VEGETABLES</button>
                                    </div>

                                </div>

                                <div >
                                    {

                                        display === "ALL" ?
                                            <div className="container-fluid ">
                                                <div className=" mt-2 p-2">
                                                    <div className="single-item">
                                                        <div className="row g-4 d-flex justify-content-center" >
                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-1.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-3.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-4.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-5.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-1.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6  ">
                                                                <div className='ProductBox'>
                                                                    <div className='' style={{ backgroundColor: "#fff" }}>
                                                                        <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                                                                    </div>
                                                                    <div className='mt-2 text-center'>
                                                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                                                            <h2 className='ProductText' >
                                                                                Meatballs
                                                                            </h2>
                                                                            <h6 >
                                                                                $ 20.00
                                                                            </h6>

                                                                            <div>
                                                                                <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                    <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                </ul>
                                                                            </div>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            : display === "CLUB" ?
                                                <div className="container-fluid ">
                                                    <div className="mt-2 p-2 ">
                                                        <div className="single-item">
                                                            <div className="row g-4 d-flex" >
                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                    <div className='ProductBox'>
                                                                        <div className='' style={{ backgroundColor: "#fff" }}>
                                                                            <img className='w-100 ' src={require("../Img/product-1.png")} alt='works-at-iclodsoft' />
                                                                        </div>
                                                                        <div className='mt-2 text-center'>
                                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                <h2 className='ProductText' >
                                                                                    Meatballs
                                                                                </h2>
                                                                                <h6 >
                                                                                    $ 20.00
                                                                                </h6>

                                                                                <div>
                                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                    <div className='ProductBox'>
                                                                        <div className='' style={{ backgroundColor: "#fff" }}>
                                                                            <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                                                                        </div>
                                                                        <div className='mt-2 text-center'>
                                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                <h2 className='ProductText' >
                                                                                    Meatballs
                                                                                </h2>
                                                                                <h6 >
                                                                                    $ 20.00
                                                                                </h6>

                                                                                <div>
                                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                    <div className='ProductBox'>
                                                                        <div className='' style={{ backgroundColor: "#fff" }}>
                                                                            <img className='w-100 ' src={require("../Img/product-3.png")} alt='works-at-iclodsoft' />
                                                                        </div>
                                                                        <div className='mt-2 text-center'>
                                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                <h2 className='ProductText' >
                                                                                    Meatballs
                                                                                </h2>
                                                                                <h6 >
                                                                                    $ 20.00
                                                                                </h6>

                                                                                <div>
                                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                    <div className='ProductBox'>
                                                                        <div className='' style={{ backgroundColor: "#fff" }}>
                                                                            <img className='w-100 ' src={require("../Img/product-4.png")} alt='works-at-iclodsoft' />
                                                                        </div>
                                                                        <div className='mt-2 text-center'>
                                                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                <h2 className='ProductText' >
                                                                                    Meatballs
                                                                                </h2>
                                                                                <h6 >
                                                                                    $ 20.00
                                                                                </h6>

                                                                                <div>
                                                                                    <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                        <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                : display === "OFFICE" ?
                                                    <div className="container-fluid ">
                                                        <div className="mt-2 p-2 ">
                                                            <div className="single-item">
                                                                <div className="row g-4 d-flex" >
                                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                        <div className='ProductBox'>
                                                                            <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                <img className='w-100 ' src={require("../Img/product-1.png")} alt='works-at-iclodsoft' />
                                                                            </div>
                                                                            <div className='mt-2 text-center'>
                                                                                <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                    <h2 className='ProductText' >
                                                                                        Meatballs
                                                                                    </h2>
                                                                                    <h6 >
                                                                                        $ 20.00
                                                                                    </h6>

                                                                                    <div>
                                                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                        <div className='ProductBox'>
                                                                            <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                                                                            </div>
                                                                            <div className='mt-2 text-center'>
                                                                                <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                    <h2 className='ProductText' >
                                                                                        Meatballs
                                                                                    </h2>
                                                                                    <h6 >
                                                                                        $ 20.00
                                                                                    </h6>

                                                                                    <div>
                                                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                        <div className='ProductBox'>
                                                                            <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                <img className='w-100 ' src={require("../Img/product-3.png")} alt='works-at-iclodsoft' />
                                                                            </div>
                                                                            <div className='mt-2 text-center'>
                                                                                <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                    <h2 className='ProductText' >
                                                                                        Meatballs
                                                                                    </h2>
                                                                                    <h6 >
                                                                                        $ 20.00
                                                                                    </h6>

                                                                                    <div>
                                                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                        <div className='ProductBox'>
                                                                            <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                <img className='w-100 ' src={require("../Img/product-4.png")} alt='works-at-iclodsoft' />
                                                                            </div>
                                                                            <div className='mt-2 text-center'>
                                                                                <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                    <h2 className='ProductText' >
                                                                                        Meatballs
                                                                                    </h2>
                                                                                    <h6 >
                                                                                        $ 20.00
                                                                                    </h6>

                                                                                    <div>
                                                                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : display === "RESIDENTIAL" ?
                                                        <div className="container-fluid ">
                                                            <div className="mt-2 p-2 ">
                                                                <div className="single-item">
                                                                    <div className="row g-4 d-flex" >
                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                            <div className='ProductBox'>
                                                                                <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                    <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                                                                                </div>
                                                                                <div className='mt-2 text-center'>
                                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                        <h2 className='ProductText' >
                                                                                            Meatballs
                                                                                        </h2>
                                                                                        <h6 >
                                                                                            $ 20.00
                                                                                        </h6>

                                                                                        <div>
                                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                            <div className='ProductBox'>
                                                                                <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                    <img className='w-100 ' src={require("../Img/product-5.png")} alt='works-at-iclodsoft' />
                                                                                </div>
                                                                                <div className='mt-2 text-center'>
                                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                        <h2 className='ProductText' >
                                                                                            Meatballs
                                                                                        </h2>
                                                                                        <h6 >
                                                                                            $ 20.00
                                                                                        </h6>

                                                                                        <div>
                                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                            <div className='ProductBox'>
                                                                                <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                    <img className='w-100 ' src={require("../Img/product-4.png")} alt='works-at-iclodsoft' />
                                                                                </div>
                                                                                <div className='mt-2 text-center'>
                                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                        <h2 className='ProductText' >
                                                                                            Meatballs
                                                                                        </h2>
                                                                                        <h6 >
                                                                                            $ 20.00
                                                                                        </h6>

                                                                                        <div>
                                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                                            <div className='ProductBox'>
                                                                                <div className='' style={{ backgroundColor: "#fff" }}>
                                                                                    <img className='w-100 ' src={require("../Img/product-3.png")} alt='works-at-iclodsoft' />
                                                                                </div>
                                                                                <div className='mt-2 text-center'>
                                                                                    <NavLink className='text-decoration-none' to='/productashienro'>
                                                                                        <h2 className='ProductText' >
                                                                                            Meatballs
                                                                                        </h2>
                                                                                        <h6 >
                                                                                            $ 20.00
                                                                                        </h6>

                                                                                        <div>
                                                                                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                                                                                <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </NavLink>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : <div>
                                                            somethings went wrong
                                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TernaryOperator