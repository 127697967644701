import React from 'react'
import "./Home.css"
// import { NavLink } from 'react-router-dom'
import ProductSilder from './ProductSilder'
import TernaryOperator from './TernaryOperator'
import { NavLink } from 'react-router-dom'
import SecondSlider from './SecondSlider'
import ThirdSilder from './ThirdSilder'

const Home = () => {
  return (
    <>

      <section>
        <div className="container-fluid p-0 " >
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
            <div className="carousel-inner FontFamilyMontSerret">
              <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../Img/Silde1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    We Offer Premium &
                  </h2>
                  <h3>
                    100% Organic Food
                  </h3>
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                  </h6>
                  <button>
                    Purchase
                  </button>
                </div>
              </div>

              <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Silde2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    We Offer Premium &
                  </h2>
                  <h3>
                    100% Organic Food
                  </h3>
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                  </h6>
                  <button>
                    Purchase
                  </button>
                </div>
              </div>

              <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Silde3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    We Offer Premium &
                  </h2>
                  <h3>
                    100% Organic Food
                  </h3>
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                  </h6>
                  <button>
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container-lg FeatureMain ">
            <div className="row mt-3 g-4 MainBg">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='d-flex align-items-center' style={{ gap: "20px" }}>
                  <div className='IconFood'>
                    <i class="fa-solid fa-truck "></i>
                  </div>
                  <div className='Feature-Content'>
                    <h2>
                      Free Shipping
                    </h2>
                    <p>
                      Free Shipping On All US
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='d-flex align-items-center' style={{ gap: "20px" }}>
                  <div className='IconFood'>
                    <i class="fa-solid fa-shield"></i>
                  </div>
                  <div className='Feature-Content'>
                    <h2>
                      Money Returns
                    </h2>
                    <p>
                      Within 30 Days
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='d-flex align-items-center' style={{ gap: "20px" }}>
                  <div className='IconFood'>
                    <i class="fa-solid fa-sack-dollar"></i>
                  </div>
                  <div className='Feature-Content'>
                    <h2>
                      Support 24/7
                    </h2>
                    <p>
                      Contact Us 24 Hours A Day
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="container-lg  pb-5">
            <div className="row mt-3 g-4">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='Recent'>
                  <img className='w-100' src={require("../Img/HotDealsUp1.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h2>
                      We Offer Premium & 100% <br />
                      Organic Food
                    </h2>
                    <button type="button" class="btn p-0" >Shop Now</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='Recent'>
                  <img className='w-100' src={require("../Img/HotDealsUp2.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h3>
                      Special recipe
                    </h3>
                    <h4>
                      Fruits Taste
                    </h4>
                    <h5>
                      Made with meat, vegetables <br />
                      and cheese
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className='Recent'>
                  <img className='w-100' src={require("../Img/HotDealsUp3.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h4>
                      Sale off 30%
                    </h4>
                    <h5>
                      All Chocolate Products
                    </h5>
                    <div className='LastBtn'>
                      <button type="button" class="btn" >Order Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container-lg mt-3 mb-5 laila-semibold ProductPadding"  >
          <div className="row align-items-center g-4 p-lg-2 mt-3"   >
            <div className="col-lg-3 col-md-6 mb-lg-5">
              <NavLink className='text-decoration-none' to='/productashienro'>
                <div className='ProductBox'>
                  <div className='mb-3'>
                    <img className='w-100 ' src={require("../img/product1.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex justify-content-center ReadMore'>
                      <NavLink className='text-decoration-none' to='/productashienro'>
                        <button className='text-center'>
                          Read more
                        </button>
                      </NavLink>
                    </div>
                    <NavLink className='text-decoration-none' to='/productashienro'>
                      <h2 className='ProductText' >
                        ASHIENRO 50 Tablet
                      </h2>
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 mb-lg-5">
              <NavLink className='text-decoration-none' to='/productashimedin'>
                <div className='ProductBox'>
                  <div className='mb-3'>
                    <img className='w-100 ' src={require("../Img/product2.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex justify-content-center ReadMore'>
                      <NavLink className='text-decoration-none' to='/productashimedin'>
                        <button className='text-center'>
                          Read more
                        </button>
                      </NavLink>
                    </div>
                    <NavLink className='text-decoration-none' to='/productashimedin'>
                      <h2 className='ProductText'  >
                        ASHIMEDIN 5 Tablet
                      </h2>
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 mb-lg-5">
              <NavLink className='text-decoration-none' to='/productashiverplus'>
                <div className='ProductBox'>
                  <div className='mb-3'>
                    <img className='w-100 ' src={require("../img/product3.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex justify-content-center ReadMore'>
                      <NavLink className='text-decoration-none' to='/productashiverplus'>
                        <button className='text-center'>
                          Read more
                        </button>
                      </NavLink>
                    </div>
                    <NavLink className='text-decoration-none' to='/productashiverplus'>
                      <h2 className='ProductText' >
                        ASHIVER PLUS Tablet
                      </h2>
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section > */}

      <ProductSilder />

      <TernaryOperator />

      <section>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
              <div className='Recent'>
                <img className='w-100' src={require("../Img/ad-img-1-1.png")} alt="icloudsoft_logo" />
                <div className='ProductBottom'>
                  <h2>
                    We Offer Premium & <br />
                    100% Organic Food
                  </h2>
                  <button type="button" class="btn p-0" >Shop Now</button>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
              <div className='Recent'>
                <img className='w-100' src={require("../Img/ad-img-1-2.png")} alt="icloudsoft_logo" />
                <div className='ProductBottom'>
                  <h3>
                    Vegetables <span>
                      Big Sale
                    </span> Fresh <br />
                    Farm Products
                  </h3>
                  <button type="button" class="btn p-0" >Shop Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container-lg FeatureMain mt-5">

            <div className="row align-items-center">

              <div className="col-xl-8 col-lg-8">
                <div className='LatestArrivals'>
                  <h2>
                    Latest Arrivals
                  </h2>
                  <h6>
                    Feature Dishes
                  </h6>
                </div>
                <div className="row g-4">

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-6' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-6 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-6' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-7.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-6 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-6' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-6 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-6' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-8.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-6 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3">
                <div className='ProductBox' style={{ marginTop: "70px" }}>
                  <div className='' style={{ backgroundColor: "#fff" }}>
                    <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='mt-4 text-center pt-4 pb-4'>
                    <NavLink className='text-decoration-none' to='/productashienro'>
                      <h2 className='ProductText' >
                        Meatballs
                      </h2>
                      <h6>
                        $ 20.00
                      </h6>

                      <div>
                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                          <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                          <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                          <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                          <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                          <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                        </ul>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SecondSlider />

      <section>
        <div className="container-fluid">
          <div className="container-lg FeatureMain mt-5">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-xl-5 col-lg-5">
                <div className='LatestArrivals'>
                  <h2>
                    Best Seller
                  </h2>
                  <h6>
                    Oars Organic
                  </h6>
                </div>

                <div className="row g-4">
                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6 ">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-7.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2">
                <div>
                  <img className='w-100 ' src={require("../Img/Middle.png")} alt='works-at-iclodsoft' />
                </div>
              </div>

              <div className="col-xl-5 col-lg-5">
                <div className='LatestArrivals'>
                  <h2>
                    New Arrivals
                  </h2>
                  <h6>
                    Our Store
                  </h6>
                </div>

                <div className="row g-4">
                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6 ">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-6.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-7.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 mt-2 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-11 col-lg-6 col-md-6 col-sm-6">
                    <div className='ProductBox d-flex align-items-center'>
                      <div className='col-lg-5' style={{ backgroundColor: "#fff" }}>
                        <img className='w-100 ' src={require("../Img/product-2.png")} alt='works-at-iclodsoft' />
                      </div>
                      <div className='col-lg-5 text-center'>
                        <NavLink className='text-decoration-none' to='/productashienro'>
                          <h2 className='ProductText' >
                            Meatballs
                          </h2>
                          <h6 >
                            $ 20.00
                          </h6>

                          <div>
                            <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#295063" }} >
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                              <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                            </ul>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ThirdSilder />

      <section>
        <div className="container-fluid">
          <div className="container-lg mt-5">
            <div className='TopCategories '>
              <div>
                <h2>
                  Latest News
                </h2>
                <h6>
                  From Blog
                </h6>
              </div>
            </div>
            <div className="row p-2 g-4 pb-5">
              <div className="col-xl-4 col-lg-4">
                <div className='ProductBox '>
                  <div className='' style={{ backgroundColor: "#fff" }}>
                    <img className='w-100 ' src={require("../Img/LatestNews1.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='MainBlog'>
                    <NavLink className='text-decoration-none' to='/productashienro'>
                      <h2 className='ProductTextBlog text-start' >
                        Latin derived from Cicero’s
                      </h2>
                      <span className='Date' >
                        <i class="fa-solid fa-calendar-days me-2"></i> <time datetime="2023-03-23T04:59:46+1:00">23. March 2023 </time>
                        &nbsp;  -
                        &nbsp;
                        <span className='Views'>
                          <i class="fa-solid fa-eye me-1"></i>   6743 Views
                        </span>
                      </span>

                      <div className='LatestBlog'>
                        <button type="button" class="btn p-0" >Read More
                          <i class="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <div className='ProductBox '>
                  <div className='' style={{ backgroundColor: "#fff" }}>
                    <img className='w-100 ' src={require("../Img/LatestNews2.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='MainBlog'>
                    <NavLink className='text-decoration-none' to='/productashienro'>
                      <h2 className='ProductTextBlog text-start' >
                        Create timelines for the
                      </h2>
                      <span className='Date' >
                        <i class="fa-solid fa-calendar-days me-2"></i> <time datetime="2023-03-23T04:59:46+1:00">23. March 2023 </time>
                        &nbsp;  -
                        &nbsp;
                        <span className='Views'>
                          <i class="fa-solid fa-eye me-1"></i> 14034 Views
                        </span>
                      </span>

                      <div className='LatestBlog'>
                        <button type="button" class="btn p-0" >Read More
                          <i class="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <div className='ProductBox '>
                  <div className='' style={{ backgroundColor: "#fff" }}>
                    <img className='w-100 ' src={require("../Img/LatestNews3.png")} alt='works-at-iclodsoft' />
                  </div>
                  <div className='MainBlog'>
                    <NavLink className='text-decoration-none' to='/productashienro'>
                      <h2 className='ProductTextBlog text-start' >
                        Keep Your Fruits frash
                      </h2>
                      <span className='Date' >
                        <i class="fa-solid fa-calendar-days me-2"></i> <time datetime="2023-03-23T04:59:46+1:00">23. March 2023 </time>
                        &nbsp;  -
                        &nbsp;
                        <span className='Views'>
                          <i class="fa-solid fa-eye me-1"></i> 559 Views
                        </span>
                      </span>

                      <div className='LatestBlog'>
                        <button type="button" class="btn p-0" >Read More
                          <i class="fa-solid fa-angle-right"></i>
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg " style={{ padding: "30px" }} >
          <div className='row p-lg-5 pt-5 pb-5' style={{ backgroundImage: `url(${require("../Img/FormBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderRadius: "10px" }}>
            <div className="col-xl-6">
              <div className='GetConnected'>
                <h6>
                  GET CONNECTED
                </h6>
                <h2>
                  Subscribe <span>
                    Newsletter
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-xl-6">
              <div className='mt-3 mb-3'>
                <form action="#" className='NewsLetter-style1'>
                  <div className="form-group m-0">
                    <input required type="email" placeholder='Enter Your Mail' className='form-control' />
                    <button type='submit' className='Vs-btn'>
                      Subscribe Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Home