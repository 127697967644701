import React from 'react'
import "./SecondSlider.css"

const SecondSlider = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/shop-CategoryBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container-lg mt-5 pt-lg-5 pb-lg-5">
                        <div className='TopCategories'>
                            <h2>
                                Shop By Categories
                            </h2>
                            <h6>
                                Top Categoris
                            </h6>
                        </div>
                        <div className="row p-2 g-4 pb-5">
                            <div className="testimonials mt-lg-4">
                                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row g-4 d-flex justify-content-center" >
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Spinach
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Kale
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider3.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Nectarines
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider4.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Wraps & Rolls
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row g-4 d-flex " >
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider5.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Strawberries (250g)
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Spinach
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Kale
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider3.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Nectarines
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row g-4 d-flex " >
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider4.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Wraps & Rolls
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider5.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Strawberries (250g)
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider1.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Spinach
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 ">
                                                    <div className='SecondSlider'>
                                                        <div className='mb-3' style={{ backgroundColor: "#fff" }}>
                                                            <img className='w-100 ' src={require("../Img/SecondSlider2.png")} alt='works-at-iclodsoft' />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <button>
                                                                Kale
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default SecondSlider