import React from 'react'
import "./Navbar.css"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../Img/banner1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "50px" }}>
                </div>

                <section>
                    <div className="container-fluid d-md-none d-none d-lg-block " style={{ backgroundColor: "#1a1a1a" }} >
                        <div className="container-lg laila-bold lato-black" >
                            <div className="row">
                                <ul class=" d-flex justify-content-between align-items-center" style={{ listStyle: "none" }} >
                                    <div className='mt-1'  >
                                        <div id="google_translate_element"></div>
                                    </div>

                                    <div className='d-flex justify-content-around '>
                                        <li className="mt-3 pe-2" style={{ fontSize: "11px", lineHeight: "20px", fontWeight: "400", color: "#fff ", letterSpacing: "1px" }} >
                                            <i class="fa-solid fa-truck-fast me-1" style={{ fontSize: "13px" }}></i> Track Your Order &nbsp; |
                                        </li>

                                        <li className="mt-3 pe-2" style={{ fontSize: "11px", lineHeight: "20px", fontWeight: "400", color: "#fff ", letterSpacing: "1px" }} >
                                            <i class="fa-regular fa-envelope me-1" style={{ fontSize: "13px" }}></i> Newsletter &nbsp; |
                                        </li>

                                        <li className="mt-3 pe-2" style={{ fontSize: "11px", lineHeight: "20px", fontWeight: "400", color: "#fff ", letterSpacing: "1px" }} >
                                            Contact Us  &nbsp;|
                                        </li>

                                        <li className="mt-3 pe-2" style={{ fontSize: "11px", lineHeight: "20px", fontWeight: "400", color: "#fff ", letterSpacing: "1px" }} >
                                            Faq's
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#1a1a1a" }} >
                        <div className="row g-2 pt-3 pb-3 text-center d-flex justify-content-center align-items-center text-white laila-semibold">
                            <div className="col-sm-6 col-12">
                                <div className='' style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#808080", letterSpacing: "1px" }}>
                                    <i class="fa-sharp fa-regular fa-clock me-lg-1"></i> Monday to Friday - 10AM to 6PM
                                </div>
                            </div>

                            <div className="col-sm-6 col-12">
                                <div>
                                    <span className='SocialIcon  text-center ms-lg-3'>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                        <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-fluid">
                        <div className="container-lg laila-semibold" >
                            <div className="row g-2 d-flex justify-content-center align-items-center justify-content-around header-logo-contact-section">
                                <div className="col-lg-3 LogoStyle">
                                    {/* <div className='LogoSection'>
                                        <NavLink className="text-decoration-none" to="/">
                                            <h4>
                                                <i>
                                                    <span>F</span>
                                                    oodana
                                                </i>
                                            </h4>
                                        </NavLink>
                                    </div> */}

                                    <div className='mb-1'>
                                        <img className='' src={require("../Img/logo.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='laila-semibold '>
                                        <h6 className=' mt-2 pb-1' style={{ fontSize: "14px", fontWeight: "600" }}>
                                            <i class="fa-sharp fa-regular fa-clock me-2"></i>  Mon-Fri 8:00 AM - 20:00 PM Satutday Closed
                                        </h6>
                                        <h6 className='' style={{ fontSize: "14px", fontWeight: "600" }}>
                                            <i class="fa-solid fa-phone me-2"></i> (+800) 111 2020, (+700) 333 44 555
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='d-flex justify-content-center laila-semibold mt-2'>
                                        <span className='UserIcon'>
                                            <i class="fa-regular fa-user"></i>
                                        </span>
                                        <ul className='d-flex align-items-center pt-2' style={{ listStyle: "none", paddingLeft: "10px" }}>
                                            <li className=" pe-2" style={{ fontSize: "12px", lineHeight: "20px", fontWeight: "800", color: "#1a1a1a ", letterSpacing: "1px" }} >
                                                Login &nbsp; /
                                            </li>

                                            <li className=" pe-3" style={{ fontSize: "12px", lineHeight: "20px", fontWeight: "800", color: "#1a1a1a ", letterSpacing: "1px" }} >
                                                Signup
                                            </li>
                                        </ul>

                                        <span className='UserIcon'>
                                            <i class="fa-regular fa-heart"></i>
                                        </span>

                                        <span className='UserIcon'>
                                            <i class="fa-solid fa-cart-shopping"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>

            <section className='Bottom-Header'>
                <div className="container-fluid  p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#659833" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0 " >
                        <div className="container ">
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-start">
                                    <li className="nav-item dropdown pe-lg-4">
                                        <NavLink className="nav-link" to='/' >
                                            HOME  <span className='ms-1'>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </NavLink>
                                        <ul className="dropdown-menu ">
                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/"> Home Variation 2 </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Home Variation 3 </NavLink>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-4">
                                        <NavLink className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            SHOP <span className='ms-1'>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/services"> Sample Page </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Cart </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Checkout </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> My account </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-4">
                                        <NavLink className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            BLOG <span className='ms-1'>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/services"> Sample Page </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Cart </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Checkout </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> My account </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-4">
                                        <NavLink className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            PAGES <span className='ms-1'>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/services"> Sample Page </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Cart </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Checkout </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> My account </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item pe-lg-4">
                                        <NavLink className="nav-link " aria-current="page" to="/contact">CONTACT</NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-none ">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' >
                                <NavLink className="text-decoration-none" to="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </NavLink>
                            </div>
                            <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4 mb-3" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "95%", marginLeft: "8px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </NavLink>
                                    </li>



                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

        </>
    )
}

export default Navbar