import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Navbar from './Navbar'
import ProductSilder from './ProductSilder'
import TernaryOperator from './TernaryOperator'
import SecondSlider from './SecondSlider'
import ThirdSilder from './ThirdSilder'
import Error from './Error'
import Footer from './Footer'
import TopBottomButton from './TopBottomButton'


const Routing = () => {
    return (
        <>

            <React.Fragment>
                <header>
                    <Navbar />
                </header>

                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/productsilder' element={<ProductSilder />} />
                        <Route exact to path='/ternaryoperator' element={<TernaryOperator />} />

                        <Route exact to path='/secondslider' element={<SecondSlider />} />
                        <Route exact to path='/thirdsilder' element={<ThirdSilder />} />

                        <Route exact to path='/topbottombutton' element={<TopBottomButton />} />

                        <Route exact to path='*' element={<Error />} />

                    </Routes>
                </main>
                <TopBottomButton />
                <footer>
                    <Footer />
                </footer>


            </React.Fragment>

        </>
    )
}

export default Routing