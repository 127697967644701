import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid pt-lg-3 pb-3 d-none d-sm-block" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container-lg laila-semibold pt-lg-4">
                        <div className="row p-lg-3 g-4 d-flex justify-content-around">
                            <div className="col-lg-3 col-md-6 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >Contact Us</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1'>
                                        <p className='' style={{ fontSize: "14px", marginBottom: "25px", lineHeight: "23px", color: "#6c6c6c", fontWeight: "400" }}>
                                            6Fifth Avenue 5501, Broadway, New York Morris Street London London
                                        </p>
                                        <NavLink to='about' className='text-decoration-none FooterHoverFirst' ><i class="fa-solid fa-paper-plane me-2"></i> <p className='ps-lg-2' >foodano@email.com</p></NavLink>
                                        <NavLink to='about' className='text-decoration-none FooterHoverFirst' ><i class="fa-solid fa-fax me-2"></i> <p className='ps-lg-2' >+(123) 4567 89000</p></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >Usefull Links</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <NavLink to='main' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2 " ></i> <p className='ps-lg-1'>Shop</p></NavLink>
                                        <NavLink to='services' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Blog</p></NavLink>
                                        <NavLink to='about' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Contact Us</p></NavLink>
                                        <NavLink to='product' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>FAQ Page</p></NavLink>
                                        <NavLink to='contact' className='text-decoration-none FooterHover'> <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Services</p></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >Follow Us Now</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <nav className='h6FontFamily1 ' style={{ fontSize: "17px", }}>
                                        <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                            <NavLink to='main' className='text-decoration-none FooterHover' > <i class="fa-brands fa-facebook-f me-2"></i> <p className='ps-lg-1'>Facebook</p></NavLink>
                                            <NavLink to='services' className='text-decoration-none FooterHover' > <i class="fa-brands fa-twitter me-2"></i> <p className='ps-lg-1'>Twitter</p></NavLink>
                                            <NavLink to='about' className='text-decoration-none FooterHover' ><i class="fa-solid fa-basketball me-2"></i> <p className='ps-lg-1'>Dribble</p></NavLink>
                                            <NavLink to='product' className='text-decoration-none FooterHover' > <i class="fa-brands fa-pinterest me-2"></i><p className='ps-lg-1'>Pinterest</p></NavLink>
                                            <NavLink to='contact' className='text-decoration-none FooterHover'> <i class="fa-brands fa-linkedin-in me-2"></i><p className='ps-lg-1'>Linkedin</p></NavLink>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >Get Direction</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3'>
                                        <iframe className='w-100 m-0' title="gmap" style={{ height: "150px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.1583088354!2d-74.11976389828038!3d40.697663748695746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1612886249367!5m2!1sen!2sbd">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex  mt-4 justify-content-center align-items-center border-top" >
                            <div className='text-center pt-4' style={{ fontSize: "14px", fontWeight: "600", paddingBottom: "8px", color: "#6c6c6c" }} >
                                © 2023 Foodano. All rights reserved by Vecuro.
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid pb-3 p-0 d-sm-none d-block " style={{ backgroundColor: "#32409A" }}>
                    <div className="container pt-4 p-3 laila-semibold ">
                        <div className="row  g-4 d-flex justify-content-center">
                            <div className="col-lg-3 col-md-6 text-white ">
                                <div className='mt-2 pt-2'>
                                    <p style={{ color: "#ccdbeb", fontSize: "17px", lineHeight: "27px", fontWeight: "400" }}>
                                        Amit Water Supplier is a leading provider of best quality Packaged Drinking Water Supplier in Pune.
                                        At Amit Water , we maintain the high standards when delivering water to our valued customers.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white ' style={{ fontSize: "24px", fontWeight: "800" }} >Useful NavLinks</h6>
                                </div>
                                <div className=' pt-2'>
                                    <nav className='h6FontFamily1 ms-lg-3' style={{ fontSize: "17px", }}>
                                        <div className='mt-1 fontFamilyHeader1 ' style={{ fontSize: "15px", lineHeight: "20px", cursor: "pointer" }}>
                                            <NavLink to='main' className='text-decoration-none FooterHover' > <p className=''>HOME</p></NavLink>
                                            <NavLink to='services' className='text-decoration-none FooterHover' > <p className=''>SERVICES</p></NavLink>
                                            <NavLink to='about' className='text-decoration-none FooterHover' > <p className=''>ABOUT US</p></NavLink>
                                            <NavLink to='product' className='text-decoration-none FooterHover' > <p className=''>PRODUCTS</p></NavLink>
                                            <NavLink to='contact' className='text-decoration-none FooterHover'> <p className=''>CONTACT US</p></NavLink>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white ' style={{ fontSize: "24px", fontWeight: "800" }} >Locations</h6>
                                </div>
                                <div className='mt-2'>
                                    <div className='pt-3 fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "600" }}>
                                        <h4 className='mb-3 mt-1 text-white' style={{ fontSize: "21px", fontWeight: "600" }} > Pune - Kothrud </h4>
                                        <p className='mb-3 pb-2' style={{ lineHeight: "27px", fontSize: "15px", fontWeight: "400" }} >
                                            Near  Lodha <br />
                                            Hospital Kothrud Pune
                                        </p>
                                        <a href='tel:9890050512' className='text-decoration-none  pb-1' style={{ fontSize: "15px", fontWeight: "400", color: "#ccdbeb", cursor: "pointer" }}> <h6 className=''><i class="fa-solid fa-phone me-3"></i> +91 9890050512</h6></a>
                                        <a href='mailto:amitdhepe2921@gmail.com' className='text-decoration-none' style={{ fontSize: "15px", fontWeight: "400", color: "#ccdbeb", cursor: "pointer" }}> <h6 className=''><i class="fa-solid fa-envelope me-3"></i>amitdhepe2921@gmail.com</h6></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2 ' style={{ fontSize: "24px", fontWeight: "800" }} >Business Hours</h6>
                                </div>
                                <div className='pt-2'>
                                    <div className=' fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>
                                        <h4 className='mb-2' style={{ lineHeight: "30px", fontSize: "16px" }}> Mon-Sat: <br />
                                            08.00 am to 06.00 pm </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" mt-4  " >
                            <div className=' text-white  ' >
                                Copyright © Amit Dhepe 2024, Inc. All Rights Reserved.
                            </div>
                            <div className='mt-3  text-white ' style={{ fontSize: '12px', cursor: "pointer" }} >
                                Design and Develop by <a href="https://icloudsoftware.co.in" className="text-white text-decoration-none" >@ icloudsoft</a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section >

        </>
    )
}

export default Footer